/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
//import "../styles/default.css"
import { Container, Row, Col } from "react-bootstrap"

const Layout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col lg={{ span: 8, offset: 2 }}>
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
          <>
            <div
              style={{
                padding: "0 1rem",
              }}
            >
              <main>{children}</main>
            </div>
          </>
        )}
      />
      </Col>
    </Row>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
